.App {
  text-align: center;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.map-container, .mapboxgl-canvas-container{
  height: 100vh;
}
.mapboxgl-canvas{
  height: 100vh !important;
}

.sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}
.capture-button{
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  outline: none;
  padding: 10px 12px;
  font-family: monospace;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  bottom: 5px;
  right: 5px;
  margin: 12px;
  border: none;
  border-radius: 50%;
  transition: all 0.3s ease;
}
.capture-link{
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  outline: none;
  padding: 10px 12px;
  font-family: monospace;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  bottom: 50%;
  margin: 12px;
  border: none;
  border-radius: 50%;
  transition: all 0.3s ease;
}
.link-right{
  right: 5px;
}
.link-left{
  left: 5px;
}
.capture-button:hover, .link-right:hover{
  box-shadow: 0px 0px 27px -5px rgba(28, 46, 63, 0.75);
  padding: 12px 14px;
}
.link-left:hover{
  box-shadow: 0px 0px 30px -5px rgba(70, 106, 139, 0.75);
  padding: 12px 14px;
}
#my-canvas{
  height: 100vh;width: 100vw;
}
.capture-button img, .capture-link img{
  height: 50px;width: 50px;
}
@media (max-width: 768px){
  .mapboxgl-ctrl-top-right{
    top: 50px;
    width: 97% ;
  }
  .sidebar{
    margin: 12px auto;
    width: -webkit-fill-available;
    left: auto;
  }
  .capture-button img, .capture-link img{
    height: 30px;width: 30px;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
